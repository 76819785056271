.wrapper {
  background-color: rgba(56, 56, 56, 0.7);
  position: fixed;
  inset: 0;
  z-index: 999;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  background-color: #efefef;
  padding: 40px;
  margin: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 16px;
  width: 50vw;
  align-items: center;
}
