.wrapper {
  padding: 40px 40px 20px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 760px) {
  .wrapper {
    padding: 10px 20px;
  }
}
