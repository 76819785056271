.footerWrapper {
  width: 100vw;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
}

.footerContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.internalLinksWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  a {
    transition: border-bottom 0.5s ease;

    &:hover {
      border-bottom: 1px solid black;
    }
  }
}

.externalLinksWrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .footerWrapper {
    gap: 16px;
  }

  .footerContent {
    padding: 0px;
    flex-direction: column-reverse;
  }

  .internalLinksWrapper {
    gap: 8px;
  }
}
