.controlsWrapper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: var(--primary-01);
  padding: 20px;
}

.controlsContent {
  display: flex;
  justify-content: space-between;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.paginationInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.filteringSelectWrappers {
  display: flex;
  gap: 8px;
}

.selectWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
}

.doNotTrainButtonWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .controlsContent {
    flex-direction: column;
    gap: 16px;
  }
}

@media screen and (max-width: 620px) {
  .actionsWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .doNotTrainButtonWrapper {
    align-self: flex-start;
  }

  .doNotTrainButton {
    align-self: flex-start;
    font-size: 12px;
    padding: 4px 8px;
  }
}
