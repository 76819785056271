.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
}

.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 24px;

  > span {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.mainContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.header {
  font-size: 72px;
  text-align: center;
}

.homePageSearchBarWrapper {
  height: 60px;
}

@media screen and (max-width: 620px) {
  .header {
    font-size: 56px;
  }

  .mainContentWrapper {
    height: 96vw;
  }

  .main {
    gap: 140px;
  }

  .homePageSearchBarWrapper {
    height: 40px;
  }
}
