.infoSection {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.domainInfoWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.domainInfoContent {
  width: -webkit-fill-available;
  white-space: nowrap;
}

.infoSectionContent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: -webkit-fill-available;
  gap: 8px;
}

.shareResultsInfoSectionContent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: -webkit-fill-available;
  gap: 8px;
}

.claimDomainSection {
  min-width: 150px;
  max-width: 300px;
}

.infoSectionLeft {
  display: flex;
  flex-direction: column;
  gap: 2px;

  p {
    max-width: 600px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.label {
  font-size: 20px;
  font-weight: bold;
}

.valueSearched {
  font-size: 20px;
  font-weight: 400;
}

.paginationWrapper {
  display: flex;
  justify-content: flex-end;
  width: -webkit-fill-available;
}

.confirmationmessage {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;

  .domainName {
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .infoSection {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .infoSectionContent {
    width: auto;
  }

  .paginationWrapper {
    width: auto;
  }

  .domainInfoWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .domainInfoContent {
    white-space: wrap;
  }
}
